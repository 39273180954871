import './App.css';
import Nav from './components/Nav';
import Painting from './components/Painting';

function App() {
  const paintings = [
    {
      artist: "Andrew Wyeth",
      name: "Wind from the Sea",
      height: 85,
      width: 125,
      price: 342.99,
      year: 1947,
      nationality: "american",
      subject: null,
      reference: null,
      style: "American Realist",
      imageRef: "https://wordsfortheyear.files.wordpress.com/2014/01/wyethwindfromthesea.jpeg"
    },
    {
      artist: "Anthony van Dyck",
      name: "The Brazen Serpent",
      height: 81,
      width: 125,
      price: 326.84,
      year: 1620,
      nationality: "flemish",
      subject: 'Exodus',
      reference: 'Numbers 21:4-9',
      style: "Baroque",
      imageRef: "https://www.artbible.info/images/mozes_slangen_grt.jpg"
    },
    {
      artist: "Caravaggio",
      name: "Supper at Emmaus",
      height: 50,
      width: 60,
      price: 96.84,
      year: 1601,
      nationality: "italian",
      subject: 'Post-Resurrection',
      reference: 'Luke 24:13-35',
      style: "Baroque",
      imageRef: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/1602-3_Caravaggio%2CSupper_at_Emmaus_National_Gallery%2C_London.jpg/1280px-1602-3_Caravaggio%2CSupper_at_Emmaus_National_Gallery%2C_London.jpg"
    },
    {
      artist: "Caravaggio",
      name: "Conversion on the Way to Damascus",
      height: 70,
      width: 50,
      price: 112.98,
      year: 1601,
      nationality: "italian",
      subject: 'Acts',
      reference: 'Acts 9:1-19',
      style: "Baroque",
      imageRef: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Conversion_on_the_Way_to_Damascus-Caravaggio_%28c.1600-1%29.jpg/800px-Conversion_on_the_Way_to_Damascus-Caravaggio_%28c.1600-1%29.jpg"
    },
    {
      artist: "Caravaggio",
      name: "The Denial of Saint Peter",
      height: 50,
      width: 70,
      price: 112.98,
      year: 1610,
      nationality: "italian",
      subject: 'Passion Week',
      reference: 'Matthew 26:69-75',
      style: "Baroque",
      imageRef: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/The_Denial_of_Saint_Peter-Caravaggio_%281610%29.jpg/1280px-The_Denial_of_Saint_Peter-Caravaggio_%281610%29.jpg"
    }
  ]

  return (
    <div className="App bg-stone-950 min-h-screen">

      {/* <Nav></Nav> */}

      <div className='container mx-auto'>

        <h1 className="text-3xl font-bold text-white pt-4 font-light tracking-wider">
          Tonys Gospel Gallery
        </h1>

        <div className='pt-6'>
          {paintings.map((p, i) => {
            return <Painting key={i} data={p}/>
          })}
        </div>

      </div>

    </div>
  );
}

export default App;
