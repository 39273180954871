import React, { useState, useEffect } from 'react';


function Painting(props) {
    const [painting, setPaiting] = useState();

    useEffect(() => {
        setPaiting(props.data);
    });

    const row = function(title, data) {

        if (data != null)
            return (
                <tr className='text-zinc-200'>
                    <th className='p-1 pr-4 pl-2 font-light uppercase'>{title}</th>
                    <td className='p-1 font-thin'>{data}</td>
                </tr>
            )
    }

  return (
    <div className='md:flex justify-center'>
        <div className='bg-stone-900 md:flex justify-center p-6 rounded mt-4 md:w-3/4'>
            <div className='p-2 flex justify-center md:w-1/2 min-w-96'>
                <img className='rounded' src={painting?.imageRef}></img>
            </div>
            <div className='p-2  md:w-1/2'>
                <table className=''>
                    <tbody className='text-left'>
                        {row('name', painting?.name)}
                        {row('height', painting?.height + ' (cm)')}
                        {row('width', painting?.width + ' (cm)')}
                        {row('price', '$' + painting?.price)}
                        {row('year', painting?.year)}
                        {row('nationality', painting?.nationality)}
                        {row('subject', painting?.subject)}
                        {row('reference', painting?.reference)}
                        {row('style', painting?.style)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default Painting